.student {
  width: 100%;
  height: calc(100% - 48px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.student .table-container {
  width: 100%;
  height: auto;
  max-height: 550px;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
  overflow-y: auto;

  margin: 1.5rem 0;
}

table {
  border-spacing: 0px;
  width: 100%;
}

table thead th {
  position: -webkit-sticky;
  position: sticky;  
  top: 0;
  z-index: 100;
}


.student th {
  background-color: #f4f6f8;
  color: #637381;
  padding: 14px 5px !important;
}

.student th,
.student td {
  /* border: 1px solid rgba(0, 0, 0, 0.151); */
  padding: 8px 2px;
  border-collapse: collapse;
  font-size: 14px;
  text-align: center;
}

.student tbody tr:nth-child(even) {
  background-color: #efefef;
}

.btnHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.btnHeader input {
  width: 300px;
  height: 35px;
  border: 0px;
  border-radius: 5px;
  padding: 8px;
  color: black;
  border: 1px solid #0c0c0c;
  box-shadow: rgba(44, 44, 44, 0.1) 0px 3px 10px;
}

.btnHeader button {
  font-size: 18px;
  padding: 8px 12px;
  border: 0;
  background-color: #107a0a;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  padding: 1rem;
  border-top: 1px solid #efefef;
  gap: 0.8rem;
}

.content {
  padding: 1.5rem;
}

.search-input {
  width: 300px;
    height: 35px;
    border: 0px;
    border-radius: 5px;
    padding: 8px;
    color: black;
    border: 1px solid #0c0c0c;
    box-shadow: rgba(44, 44, 44, 0.1) 0px 3px 10px;
}

.pagination-cmmn-btn {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #d4d4d4;
  font-size: 12px;
  height: 30px;
}

.pagination-cmmn-input {
  padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #d4d4d4;
    font-size: 12px;
    height: 25px;
}

.pagination-cmmn-select {
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
  background-color: #d4d4d4;
  font-size: 12px;
  height: 30px;
}

button.view {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}



.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #107a0a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #107a0a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} 

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #107a0a;
  border-radius: 30px;
}

input:focus + .slider {
  box-shadow: 0 0 1px #107a0a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} 

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #107a0a;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}