.manageScholarship{
    width: 100%;

}
.navBar{
    width: 100%;
}
.navBar ul{
    width: 96%;
    display: flex;
    gap: 40px;
box-shadow: 2px 0.2px 8px rgba(128, 128, 128, 0.603);
}
.navBar ul li{
    list-style: none;
    font-size: 20px;
    padding: 10px 0;
    cursor: pointer;
}