.admin-dashboard {
    width: 100%;
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.content_wrapper {
    width: 100%;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft {
    border: none;
}

.menu-list {
    .active {
            background-color: #107a0a;
        }
}

.student th {
    background-color: #f4f6f8;
    color: #637381;
}

.student {
    width: 100%;
    height: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.student .table-container {
    width: 100%;
    height: auto;
    max-height: 550px;
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    overflow-y: auto;

    margin: 1.5rem 0;
}

table {
    border-spacing: 0px;
    width: 100%;
}

table thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
    // background-color: #f4f6f8;
    // color: #637381;

    background-color: #313131;
    color: #fff;
}

th.MuiTableCell-root.MuiTableCell-head {
    // color: #637381;
    color: #fff; 
    font-size: 14px;
    font-weight: 600;  
    
    .MuiButtonBase-root.MuiTableSortLabel-root.Mui-active {
	color: rgba(255, 255, 255, 0.87);
}

.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc {
    color: #fff;
}
}




.student th {
    // background-color: #f4f6f8;
    background-color: #313131;
    // color: #637381;
    color: #fff !important;
    padding: 14px 5px !important;

    &:hover {
        color: #fff !important;
    }
}

.student th,
.student td {
    /* border: 1px solid rgba(0, 0, 0, 0.151); */
    padding: 15px 10px;
    border-collapse: collapse;
    font-size: 14px;
    text-align: left;

}

.student tbody tr:nth-child(even) {
    background-color: #efefef;
}

.btnHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    @media (max-width:768px){

    }

    @media (max-width:510px){
        flex-direction: column;
        gap:1rem;

        .MuiFormControl-root.MuiTextField-root {
            width: 100% !important;
        }
    }

    @media (max-width:320px){
        
    }
}

#simple-tabpanel-0 {
  > .MuiBox-root:first-child {
    padding: 0;
  }
}

.btn-grp {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.btnHeader input {
    width: 300px;
    height: 35px;
    border: 0px;
    border-radius: 5px;
    padding: 8px;
    color: black;
    // border: 1px solid #0c0c0c;
    box-shadow: rgba(44, 44, 44, 0.1) 0px 3px 10px;

        @media (max-width:510px){
        width: 100%;

        
    }


}

.btnHeader button {
    font-size: 18px;
    padding: 8px 12px;
    border: 0;
    background-color: #107a0a;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    width: max-content;
}

.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
    padding: 1rem;
    border-top: 1px solid #efefef;
    gap: 0.8rem;
}

.content {
    padding: 1.5rem;
}

.search {
    width: 100%;
}

.search-input {
    width: 300px;
    height: 35px;
    border: 0px;
    border-radius: 5px;
    padding: 8px;
    color: black;
    border: 1px solid #0c0c0c;
    box-shadow: rgba(44, 44, 44, 0.1) 0px 3px 10px;
}

.pagination-cmmn-btn {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #d4d4d4;
    font-size: 12px;
    height: 30px;
}

.pagination-cmmn-input {
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: #ffffff;
    border: 1px solid #d4d4d4;
    font-size: 12px;
    height: 25px;
}

.pagination-cmmn-select {
    padding: 0.5rem;
    border: none;
    border-radius: 5px;
    background-color: #d4d4d4;
    font-size: 12px;
    height: 30px;
}

button.view {
    border: none;
    box-shadow: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}



.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #107a0a;
}

input:focus+.slider {
    box-shadow: 0 0 1px #107a0a;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 30px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #107a0a;
    border-radius: 30px;
}

input:focus+.slider {
    box-shadow: 0 0 1px #107a0a;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #107a0a;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}




.img-preview-div {
    border: 1px solid gray;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0.5rem 0;

    img {
        width: 100%;
        height: 100%;
        max-height: 200px;
        display: block;
        object-fit: cover;
    }
}


.sidebar {
    span.MuiTypography-root.MuiTypography-body1.MuiListItemText-primary {
        font-size: 14px !important;
    }
}

.error-msg {
    color: #ff0000;
    font-size: 14px;
    margin-top: 5px;
    padding-left: 5px;
}


.institute-img-div {
    border: 1px solid gray;
    width: 300px;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    margin: 0.5rem 0;
    aspect-ratio: 16/9;

    img {
        width: 100%;
        height: 100%;
        max-height: 200px;
        display: block;
        object-fit: cover;
        aspect-ratio: 16/9;
    }
}

.cropper-bg {
    background-image: unset !important;
}

// styles.css
.statCard {
    transition: transform 0.3s;
}

.statCard:hover {
    transform: scale(1);
    background-color: #107a0a;
    color: #fff;
}

.statCard:hover .icon {
    filter: invert(0);
}

.icon {
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
    filter: invert(1); /* Default color */
}